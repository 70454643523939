import React from 'react';
import { Card, Col, Row } from 'antd';

import Layout from '../components/layout';
import SEO from '../components/seo';

const CustomBikes = () => (
	<Layout>
		<SEO
			title="Atelier"
			description="Notre atelier vous est ouvert pour tous types de préparations, réparations ou modifications sur-mesures."
			lang="fr"
			url="/atelier"
		/>

		<div className="box-container first">
			<div className="content-block">
				<div className="content-block-bg" />
				<h2>ATELIER</h2>

				<Row type="flex" justify="space-around" gutter={16}>
					<Col xs={24} md={12} xxl={10}>
						<Card style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', height: '100%' }}>
							<h3>ATELIER ET ENTRETIENS</h3>
							<p>Grâce à notre expérience et à notre atelier complètement remis à neuf en début 2017, nous sommes dans le mesure de vous proposer de nombreux services d'entretiens, de réparation et de diagnostiques électrique ou moteur dans les meilleures conditions possible...</p>
							<p>De plus, notre large gamme de machine et d'outils, nous permets de vous proposer la modification de votre moto actuelle ou la fabrication sur-mesure de celle de vos rêves.</p>
						</Card>
					</Col>
					<Col xs={24} md={12} xxl={10}>
						<Card style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', height: '100%' }}>
							<h3>DÉPÔT VENTE</h3>
							<p>Si vous voulez vendre votre moto mais vous n'avez pas le temps de vous en occuper, nous pouvons vous proposer notre service de dépot-vente. Nous prendrons alors en charge votre moto et ce jusqu'à la fin de sa vente.</p>
							<p>La moto sera alors visible dans notre Showroom et sur nos différents supports d'annonce comme facebook, anibis et motoscout24.</p>
							<p>Selon votre accord, nous donnerons la possibilité au potentiel acheteur de l'essayé, si celle-ci lui plaît nous nous occuperons alors de son immatriculation et son passage à la visite.</p>
						</Card>
					</Col>
				</Row>
			</div>
		</div>
	</Layout>
);

export default CustomBikes;
